// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap/Button';
import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import NavBar from "./NavBar";

const OrderDetails = () => {
  const { state } = useLocation();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const token = localStorage.getItem("token");
  const [products, setProducts] = useState([]);

  // const getOrderDetails = () => {
  //   axios
  //     .post(`${BASE_URL}admin/getOrderDetails/${state._id}`, {}, {
  //       headers: {
  //         "Content-Type": "Application/json",
  //         "x-access-token": `${token}`,
  //         "version": "1.0.0"
  //       }
  //     }).then((response) => {
  //       if (response.data.success) {
  //         console.log("order details", response.data.data);
  //         setProducts(response.data.data);

  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     }).catch((error) => {
  //       console.log(error.message);
  //     })
  // }

  useEffect(() => {
    // getOrderDetails();
    console.log("state====>", state);
  }, []);
  return (
    <>
      <NavBar />
      <div className="container ">
        <div className="row d-flex justify-content-center align-items-center mt-5">
          <div
            className="col-10 p-5"
            style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}
          >
            <div className="row">
              <h2 className="text-center">Order Details</h2>
              <div className="col-md-6">
                <label className="formleb">Custome Name</label>
                <input
                  className="form-control forminp"
                  value={state.customer_name}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Custome Email</label>
                <input
                  className="form-control forminp"
                  value={state.customer_email_id}
                  disabled
                />
              </div>
             
              <div className="col-md-6">
                <label className="formleb">Customer Mobile Number</label>
                <input
                  className="form-control forminp"
                  value={state.customer_mobile_no}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Customer Address</label>
                <input
                  className="form-control forminp"
                  value={state.customer_address1 ? state.customer_address1 : ""}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Customer Address2</label>
                <input
                  className="form-control forminp"
                  value={state.customer_address2}
                  disabled
                />
              </div>
             

              <div className="col-md-6">
                <label className="formleb">State</label>
                <input
                  className="form-control forminp"
                  value={state.state}
                  disabled
                />
              </div>

              <div className="col-md-6">
                <label className="formleb">City</label>
                <input
                  className="form-control forminp"
                  value={state.city}
                  disabled
                />
              </div>

              <div className="col-md-6">
                <label className="formleb">Pin Code</label>
                <input
                  className="form-control forminp"
                  value={state.zip_code}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Order Date</label>
                <input
                  className="form-control forminp"
                  value={moment(state.createdAt).format("MM/DD/YYYY")}
                  disabled
                />
              </div>

              <div className="col-md-6">
                <label className="formleb">Amount</label>
                <input
                  className="form-control forminp"
                  value={state.amount}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Shiping Charges</label>
                <input
                  className="form-control forminp"
                  value={state.shipping_charge}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="formleb">Total Amount</label>
                <input
                  className="form-control forminp"
                  value={state.total_amount}
                  disabled
                />
              </div>

              <br />
              <br />

              {state.Order_details.length > 0 &&
                state.Order_details.map((item, index) => (
                  <>
                    <hr className="mt-5" />
                   <div className="col-md-6" key={index}>
                      <label className="formleb">Product name</label>
                      <input
                        type="text"
                        className="form-control forminp"
                        name="name"
                        value={(item?.Products_details!=undefined)?item.Products_details[0].name:""}
                        disabled
                      />
                    </div>

                    <div className="col-md-6">
                      <label className="formleb">Quantity</label>
                      <input
                        type="text"
                        className="form-control forminp"
                        name="quantity"
                        value={(item?.quantity!=undefined)?item.quantity:""}
                        disabled
                      />
                    </div>

                    
                   
                    <div className="col-md-6">
                      <label className="formleb">Price</label>
                      <input
                        type="text"
                        className="form-control forminp"
                        name="price"
                        value={item.Products_details[0].price}
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="formleb">Product images</label>

                      <img
                        src={item.Products_details[0].image}
                        key={index}
                        className="img-fluid"
                        width={100}
                        height={80}
                      />
                    </div>

                    <hr />
                  </>
                ))}

          
              
            
              
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OrderDetails;
